const deviceTranslation = {
  mode: 'en',
  menu: 'Device',
  listMenu: 'Manage Device',
  versionMenu: 'Manage Mommybox Version',
  agentVersionMenu: 'Manage Agent Version',

  searchDevice: 'Search Device',
  editDeviceDetail: 'Device Info Edit',
  editDevice: 'Edit Device Info',
  addDevice: 'Add Device',
  addDeviceVersion: 'Add Version',
  recordingSetting: 'Device Settings',
  deviceSetting: 'Peripheral Device Settings',
  functionSetting: 'Function Settings',
  captureSwitchSetting: 'Capture Switch Settings',
  recordingScreenSetting: 'Screen Settings',
  info: 'Device Information',
  hospitalInfo: 'Account Information',
  sysInfo: 'Device data collection list',
  sysInfoDescription: 'Settings are only available when the device is online.',

  hospitalLabel: 'Account',
  hospitalRoomLabel: 'Location',
  maxRecordingTimeLabel: 'Maximum Recording Time',
  motionThresholdLabel: 'Motion Detection Sensitivity',
  recordingCardSelectModeLabel: 'Audio Recording Device',
  captureSoundLabel: 'Beep Volume for Photo Capture',
  captureOnRecordingLabel: 'Capture During Recording Only?',
  pauseResumeLabel: '"Resume" & "Pause" Sound',
  diaryCaptureLabel: 'Allow Photo Capture',
  checkInvalidBarcodeLabel: 'Check Expired Barcode',
  printerLabel: 'Allow Printer',
  recordingBrightnessLabel: 'Brightness',
  recordingContrastLabel: 'Contrast',

  devicePlaceHolder: 'Enter Device Name(Serial)',
  descriptionPlaceHolder: 'Enter Device Description',
  hospitalRoomPlaceHolder: 'Select Location',
  buildNumberPlaceHolder: 'Enter Build Number',
  buildHashPlaceHolder: 'Enter Build Hash',
  versionNamePlaceHolder: 'Enter Version Name',
  versionDescriptionPlaceHolder: 'Enter Version Description',

  seq: 'Seq',
  name: 'Device Name',
  deviceName: 'Device Name (Serial Number)',
  hospital: 'Account',
  version: 'Mommybox Version',
  agentVersion: 'Agent Version',
  hospitalRoom: 'Location',
  activity: 'Last Activity',
  motionThreshold: 'Motion Sensitivity',
  maxRecordingTime: 'Max Recording Time',
  minute: 'm',
  printer: 'Printer',
  capture: 'Photo Capture',
  captureSwitch: 'Capture Switch Mode',
  recordingBrightness: 'Brightness',
  recordingContrast: 'Contrast',
  recordingCardSelectMode: 'Audio Device',
  captureOnRecording: 'Capture Only During Recording',
  checkInvalidBarcode: 'Check Expired Barcode',
  activeFlag: 'Active?',
  active: 'Activate',
  inactive: 'Deactivate',
  activeDevice: 'Active Device',
  inactiveDevice: 'Inactive Device',
  installFlag: 'Install?',
  install: 'Install',
  installDevice: 'Install Device',
  uninstallDevice: 'Uninstall Device',
  description: 'Device Description',
  record: 'Records',
  detailRecord: 'Detailed Records',
  uptime: 'Uptime',
  timeZone: 'Time Zone',

  motionThreshold001: '[0.010] Very Sensitive',
  motionThreshold005: '[0.050] Sensitive',
  motionThreshold018: '[0.180] Normal (Default)',
  motionThreshold022: '[0.220]Less Sensitive',
  motionThreshold999: '[999.999] Motion Detection Disabled',
  captureSwitchNone: 'Capture + Capture',
  captureSwitchCs: 'Capture + Record Start/Stop',
  captureSwitchCp: 'Capture + Record Resume/Pause',
  captureSwitchSp: 'Record Start/Stop + Record Resume/Pause',
  captureSwitchPs: 'Record Resume/Pause + Record Start/Stop',
  recordingCardSelectModeDefault: 'Auto Select',
  recordingCardSelectModeYuh01: 'YUH-01 3.5mm',
  recordingCardSelectModeEzcap: 'GameDock Ultra 3.5mm',
  recordingCardSelectModeAlc255: 'PN40 Front Input',
  recordingCardSelectModeUsb: 'USB Device (0d8c:0014)',
  recordingCardSelectModeAv: 'EZCap (534d:0021)',

  buildNumber: 'Build Number',
  buildHash: 'Build Hash',
  versionName: 'Version Name',
  versionDescription: 'Version Description',
  visibleFlag: 'Visibility Status',
  autoUpdateFlag: 'Auto Update Status',
  createdAt: 'Created At',
  updatedAt: 'Updated At',

  assignedRoom: 'Assigned Location Available',
  unassignedRoom: 'No Assigned Location',
  activeDeviceMessage: ': Would you like to activate this?',
  inactiveDeviceMessage: ': Would you like to deactivate this?',
  inactiveDeviceWarningMessage:
    '* Inactive devices will not be displayed in [Device Monitoring] menu.',
  descriptionMessage: '* Typically Location(Room) Name.',
  deviceReflectMessage1: '* Available from build 248',
  deviceReflectMessage2: '* Available from build 251',
  deviceReflectMessage3: '* Available from build 252',
  deviceReflectMessage4: '* Available from build 253',
  deviceReflectMessage5: '* Available from build 257',
  deviceReflectMessage6: '* Available from build 283',
  deviceReflectMessage7: '* Available from build 251.',
  recordingCardSelectModeDefaultOption: 'Auto Select (Default)',
  recordingCardSelectModeYuh01Option: 'YUH-01 3.5mm',
  recordingCardSelectModeEzcapOption: 'GameDock Ultra 3.5mm',
  recordingCardSelectModeAlc255Option: '[only PN40] Front Input, Y-jack',
  recordingCardSelectModeUsbOption: 'Universal USB Input Device (Microphone) (0d8c:0014)',
  recordingCardSelectModeAvOption: 'EZCap',
  captureSwitchNoneOption: '[none] Capture + Capture (Default)',
  captureSwitchCsOption: '[cs] Capture + Record Start/Stop',
  captureSwitchCpOption: '[cp] Capture + Record Resume/Pause',
  captureSwitchSpOption: '[sp] Record Start/Stop + Record Resume/Pause',
  captureSwitchPsOption: '[ps] Record Resume/Pause + Record Start/Stop',
  optionMessage1: '[0] Disable (Default)',
  optionMessage2: '[1] Enable',
  captureSoundOption1: '[1] Low',
  captureSoundOption2: '[3] Medium (Default)',
  captureSoundOption3: '[5] High',
  captureOnRecordingOption1: '[0] Capture Anytime',
  captureOnRecordingOption2: '[1] Capture During Recording Only',
  pauseResumeOption1: '[0] Sound On (Default)',
  pauseResumeOption2: '[1] Sound Off',
  recordingBrightnessOption1: '[-0.10] 10% Darker',
  recordingBrightnessOption2: '[-0.08] 8% Darker',
  recordingBrightnessOption3: '[-0.06] 6% Darker',
  recordingBrightnessOption4: '[-0.04] 4% Darker',
  recordingBrightnessOption5: '[-0.02] 2% Darker',
  recordingBrightnessOption6: '[0.00] 0% (Default)',
  recordingBrightnessOption7: '[0.02] 2% Brighter',
  recordingBrightnessOption8: '[0.04] 4% Brighter',
  recordingBrightnessOption9: '[0.06] 6% Brighter',
  recordingBrightnessOption10: '[0.08] 8% Brighter',
  recordingBrightnessOption11: '[0.10] 10% Brighter',
  recordingContrastOption1: '[0.98] -2% Contrast',
  recordingContrastOption2: '[1.00] 0% Contrast',
  recordingContrastOption3: '[1.01] 1% Contrast',
  recordingContrastOption4: '[1.02] 2% Contrast',
  recordingContrastOption5: '[1.03] 3% Contrast',
  recordingContrastOption6: '[1.05] 5% Contrast',
  recordingContrastOption7: '[1.07] 7% Contrast (Default)',
  deviceActiveOption1: '[1] Active',
  deviceActiveOption2: '[0] Inactive',
  deviceInstallOption1: '[1] Installed',
  deviceInstallOption2: '[0] Uninstalled',

  versionFixFlag: 'Version Fix Setting',
  versionFix: 'mommybox v3',
  agentVersionFix: 'agent v3',
  agentVersionFixTrue: '[1] Fixed',
  agentVersionFixFalse: '[0] Unfixed',
  mommyboxVersionFixTrue: '[1] Fixed',
  mommyboxVersionFixFalse: '[0] Unfixed',
  versionFixDescription: 'Auto updates are disabled when fixed.',

  inactiveKeepHospital: 'Keep Account/Location',
  inactiveMoveToInactiveRoom: 'Move to Inactive Location',
  inactiveDeleteHospital: 'Delete Account/Location',
};

export default deviceTranslation;
