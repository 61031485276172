const csTranslation = {
  mode: 'en',
  menu: 'Manage content',

  csTitle: 'Manage content',
  appUserTableTitle: 'APP User Lookup/Task',
  csRecordTableTitle: 'Manage Content',
  csTableTitle: 'History',

  csPlaceHolder: 'Search',
  csRecordPlaceHolder: 'Video Search',
  csHistoryPlaceHolder: 'History Search',
  insertSeq: 'Enter Number',
  insertDate: 'Enter Date',
  insertReason: 'Enter Reason',
  changeTargetBarcode: 'Target Barcode',
  duplicateTargetBarcode: 'Target Barcode',
  uploadVideo: 'Video File',
  hideAllPhotos: 'Hide entire session',
  unhideAllPhotos: 'Unhide entire session',

  userDetailInfo: 'User Info',
  videoDetailInfo: 'Video Info',
  videoInfo: 'Video Info',
  barcode: 'Barcode',
  signUpAt: 'Sign-Up Date',
  nickName: 'Nickname',
  babyNickName: 'Baby Nickname',
  dueDate: 'Due Date',
  marketingAgreeAt: 'Agreed terms on',
  seq: 'Number',
  name: 'Sign-Up Name',
  phoneNumber: 'Phone Number',
  status: 'User Status',
  leave: 'Deleted',
  normal: 'Normal',
  recordedAt: 'Recorded at',
  capturedAt: 'Recorded at',
  registeredAt: 'Registered at',
  hospital: 'Account',
  hospitalRoom: 'Location',
  hospitalLogin: 'HPA',
  box: 'MommyBox Control',
  device: 'MommyBox',
  activityType: 'Type',
  reason: 'Reason',
  description: 'History',
  createdAt: 'Date',
  actor: 'Handled by',
  blinded: '(Hidden)',
  videoBlind: 'Hide Video',
  blind: 'Setting to',
  setting: 'Hide',
  clear: 'Unhide',
  changeBarcode: 'Change Barcode',
  duplicateRecording: 'Duplicate Video',
  editRecording: 'Trim Video',
  edit: 'Save',
  overwriteRecording: 'Change Video',
  overwrite: 'Save',
  restoreRecording: 'Restore Edited Video',
  restore: 'Save',
  recordingSeq: 'Video Seq',
  captureSeq: 'Photo Seq',
  videoLength: 'Length',
  bucket: 'Bucket',
  file: 'File',
  recording: 'Video',
  capture: 'Photo',
  successDownload: 'Download Complete',
  failDownload: 'Download Failed',
  editTime: 'Video Trim',
  uploadFile: 'Upload File',

  csDevice: 'MommyBox/',
  csHospital: 'Account/',
  csHospitalRoom: 'Location/',
  csHospitalLogin: 'HPA/',
  csRecording: 'Video/',
  csCapture: 'Photo/',
  csBarcode: 'Barcode/',
  csBox: 'MommyBox Control/',
  csApprest: 'App Restart',
  csRemotecmd: 'Remote Command',
  csEdit: 'Edit',
  csBlind: 'Set to Hide',
  csUnblind: 'Set to Unhide',
  csDelete: 'Delete (Inactive)',
  csUpdate: 'Update',
  csCreate: 'Create',
  csRefresh: 'Refresh',
  csOverwrite: 'Change',
  csDuplicate: 'Copy',
  csLogins: 'Admin Accounts',

  downloadAll: 'Download All',
  csDownload: 'Download History',
  csExcel: 'MDA-CS_Handling_History.xlsx',

  editMessage: 'Editing is starting. It will take up to 15 minutes.',
  duplicateMessage: 'Completed: Video Duplicated.',
  overwriteMessage: 'Completed: Video Changed.',
  overwriteFailMessage: 'Error: Video Change Failed',
  restoreMessage: 'Completed: Edited video restored.',
  refreshMessage: 'Refresh thumbnails and video lengths.',
  refreshSuccessMessage: 'Success: Ultrasound video refreshed',
  refreshFailMessage:
    'Error: Failed to update video information, there may be an issue with the video.',
  searchMessage:
    'After entering a search term, press Enter to search in all sub-items. (Useful for barcode search)',
  changeBarcodeMessage: 'Enter a barcode to change barcode, or leave it blank to set/unset blind.',

  transferMessage: 'Transfer completed.',
  transferFailMessage: 'Transfer Failed',
  transferWarningMessage: 'Transfer Warning',
  recordingTransfer: 'Recording Transfer',
  failedCapture: 'Failed Captures',
  transferFailedMessage: 'Please contact support for detailed information',
};

export default csTranslation;
